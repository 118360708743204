import React, { useEffect } from "react";
import ChatMessage from "./ChatMessage";
import Spinner from "./shared/icons/spinner";

const ChatWindow = ({ messages, status }) => {
  useEffect(() => {
    scrollToLatest();
  }, [messages]);

  const scrollToLatest = () => {
    const lastMessage = document.querySelector(
      ".chat-panel .chat-message:last-child"
    );
    if (lastMessage) {
      const container = document.querySelector(".chat-panel");
      if (container) {
        container.scrollTop =
          container.scrollHeight - lastMessage.scrollHeight - 30;
      }
    }
  };

  return (
    <div className="chat-panel scrollbar h-full min-h-80 overflow-y-auto p-2 flex-grow border rounded-lg bg-card text-card-foreground shadow-sm prose prose-lg">
      {messages.map((message, index) => (
        <ChatMessage
          className="chat-message"
          key={index}
          isBot={message.role === "assistant"}
          content={message.content}
        />
      ))}
      {status === "running" && (
        <span className="m-4 chat-message text-gray-400 text-sm">
          <Spinner /> thinking...
        </span>
      )}
    </div>
  );
};

export default ChatWindow;
