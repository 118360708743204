import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export function copyToClipboard(text) {
  navigator.clipboard.writeText(text).then(function () {
  }, function (err) {
    console.error('Could not copy text: ', err);
  });
}