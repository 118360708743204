import React, { useEffect, useRef, useState } from "react";
import { sendMessage } from "./openai";
import ChatWindow from "./components/ChatWindow";
import Textarea from "./components/Textarea";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "./components/ui/collapsible";
import { Button } from "./components/ui/button";
import { ChevronsUpDown, Share } from "lucide-react";
import TopBar from "./components/TopBar";
import { SettingsDialog } from "./components/SettingsDialog";
import {
  useAPIProviderStorage,
  useGroqAPIKeyStorage,
  useGroqModalStorage,
  useOpenaiAPIKeyStorage,
  useOpenaiModalStorage,
} from "./hooks/use-local-storage";
import { ModelsByProviders } from "./components/SelectAIModel";
import { useLocalStorage } from "@uidotdev/usehooks";
import { copyToClipboard } from "./lib/utils";
import { useToast } from "./components/ui/use-toast";
import { Toaster } from "./components/ui/toaster";
import Footer from "./components/Footer";

const initialMessage = "Hello! Let talk about: ";

const defaultUserSystemMessage = `I'm a traveller.
I'm from US.`;
// Switch topics if needed.
// // Use Vietnamese only.

const defaultAssistantSystemMessage = `I'm local master chef.`;
// Change topics if needed.
// // Use Vietnamese only.
// Talk about any topic in life.

const defaultTopic = `Best food in India.`;

const CHAT_DELAY = 3000;
const MAX_CHAT_MESSAGES = 20;
function App() {
  const { toast } = useToast();
  const intervalRef = useRef(null);
  const [status, setStatus] = useState("init");
  const [isFullOption, setIsFullOption] = useState(true);
  const [provider, saveProvider] = useAPIProviderStorage("groq");
  const [openaiApiKey, saveOpenaiAPIKey] = useOpenaiAPIKeyStorage();
  const [groqApiKey, saveGroqAPIKey] = useGroqAPIKeyStorage(
    "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"
  );
  const [groqModel, saveGroqModel] = useGroqModalStorage(
    ModelsByProviders.groq[0].id
  );
  const [openaiModel, saveOpenaiModel] = useOpenaiModalStorage(
    ModelsByProviders.openai[0].id
  );

  const messagesRef = useRef([]);
  const [messages, setMessages] = useState([]);

  const [userSystemMessageContent, setUserSystemMessageContent] =
    useLocalStorage("AI1", defaultUserSystemMessage);

  const [topic, setTopic] = useLocalStorage("Topic", defaultTopic);
  const [assistantSystemMessageContent, setAssistantSystemMessageContent] =
    useLocalStorage("AI2", defaultAssistantSystemMessage);

  const updateUserSystemMessage = (event) => {
    setUserSystemMessageContent(event.target.value);
  };

  const updateAssistantSystemMessage = (event) => {
    setAssistantSystemMessageContent(event.target.value);
  };

  const updateTopic = (event) => {
    setTopic(event.target.value);
  };

  const reset = () => {
    stopChat();
    setStatus("init");

    messagesRef.current = [];
    setMessages([]);
  };

  const receiveMessage = async () => {
    if (messagesRef.current.length === 0) {
      messagesRef.current.push({
        role: "user",
        content: initialMessage + topic,
      });
      setMessages(messagesRef.current.slice());
      return;
    }

    if (messagesRef.current.length >= MAX_CHAT_MESSAGES) {
      stopChat();
      setStatus("init");
      return;
    }

    const newMessage = await sendMessage(messagesRef.current, {
      userSystemMessageContent,
      assistantSystemMessageContent,
      provider,
      openaiApiKey,
      groqApiKey,
      groqModel,
      openaiModel,
    }).catch((err) => {
      toast({
        description: err.message,
        variant: "destructive",
      });
      console.error(err);
      stopChat();
      setStatus("init");
    });

    if (newMessage) {
      messagesRef.current.push(newMessage);
      setMessages(messagesRef.current.slice());
    }
  };

  const stopChat = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  };

  const toggleChat = (off = false) => {
    if (!intervalRef.current) {
      receiveMessage();
      intervalRef.current = setInterval(receiveMessage, CHAT_DELAY);
      setStatus("running");
    } else {
      stopChat();
      setStatus("paused");
    }
  };

  const handleShare = () => {
    const baseUrl = window.location.href.split("?")[0]; // Get the base URL without query parameters
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("t", topic); // Update or set the topic parameter
    queryParams.set("ai1", userSystemMessageContent); // Set AI 1's personality parameter
    queryParams.set("ai2", assistantSystemMessageContent); // Set AI 2's personality parameter
    const shareUrl = `${baseUrl}?${queryParams.toString()}`;

    copyToClipboard(shareUrl);
    toast({ description: "Share URL Copied!", variant: "success" });
  };

  useEffect(() => {
    setIsFullOption(status === "init");
  }, [status]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const topic = queryParams.get("t");
    if (topic) {
      setTopic(topic);
    }

    const aiPersonality1 = queryParams.get("ai1");
    if (aiPersonality1) {
      setUserSystemMessageContent(aiPersonality1);
    }

    const aiPersonality2 = queryParams.get("ai2");
    if (aiPersonality2) {
      setAssistantSystemMessageContent(aiPersonality2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-screen flex flex-col">
      <TopBar />
      <div
        style={{ height: "calc(100vh - 80px)" }}
        className="flex flex-col w-full h-full max-w-4xl mx-auto p-5 flex-grow"
      >
        <div className="p-3 text-center leading-relaxed">
          <h1 className="text-2xl font-bold tracking-tighter md:text-5xl lg:leading-[1.1]">
            AI Chit-Chat
          </h1>
          <p className="text-balance">
            Watch two AI bots chat about different topics.
          </p>
        </div>

        <Collapsible open={isFullOption}>
          <CollapsibleTrigger asChild>
            <div className="w-full text-center p-3 space-x-3 space-y-3">
              <Button
                onClick={() => setIsFullOption(!isFullOption)}
                variant="outline"
                size="sm"
              >
                More Options <ChevronsUpDown className="ml-3 h-4 w-4" />
                <span className="sr-only">Toggle</span>
              </Button>
              <SettingsDialog
                s={{
                  provider,
                  saveProvider,
                  openaiApiKey,
                  saveOpenaiAPIKey,
                  groqApiKey,
                  saveGroqAPIKey,
                  groqModel,
                  saveGroqModel,
                  openaiModel,
                  saveOpenaiModel,
                }}
              />
              <Button
                onClick={handleShare}
                className="bg-green-700 hover:bg-green-600"
                size="sm"
              >
                Share this Topic <Share className="ml-3 h-4 w-4" />
              </Button>
            </div>
          </CollapsibleTrigger>
          <div className="p-3">
            <Textarea
              id="topic"
              label="Topic"
              placeholder="Enter a topic to discuss."
              value={topic}
              onChange={updateTopic}
              rows="2"
              disabled={messages.length > 0}
            />
          </div>
          <CollapsibleContent className="p-3 space-y-5">
            <Textarea
              id="ai1"
              label="Tom's Character Profile"
              placeholder="Describe the Character"
              value={userSystemMessageContent}
              onChange={updateUserSystemMessage}
              helpMessage="Provide a summary about this character. Describe their personality, hobbies, interests, and any other relevant details."
              rows="3"
            />

            <Textarea
              id="ai2"
              label="Jerry's Character Profile"
              placeholder="Describe the Character"
              value={assistantSystemMessageContent}
              onChange={updateAssistantSystemMessage}
              rows="3"
            />
          </CollapsibleContent>
        </Collapsible>

        <div className="p-2 text-center space-x-2">
          {messages.length < MAX_CHAT_MESSAGES && (
            <Button
              variant={status === "init" ? "" : "secondary"}
              onClick={toggleChat}
            >
              {status === "init"
                ? "Start"
                : status === "running"
                ? "Pause"
                : "Resume"}
            </Button>
          )}
          {messages.length > 0 && (
            <Button variant="secondary" onClick={reset}>
              Reset
            </Button>
          )}
        </div>
        <div className="text-gray-500 text-sm">
          {messages.length}/{MAX_CHAT_MESSAGES} messages
        </div>
        <ChatWindow messages={messages} status={status} />
        <Footer />
      </div>
      <Toaster />
    </div>
  );
}

export default App;
