import SelectAPIProvider from "./SelectAPIProvider";
import SelectAIModel from "./SelectAIModel";
import { Button } from "./ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Settings } from "lucide-react";

export function SettingsDialog({ s }) {
  const {
    provider,
    saveProvider,
    openaiApiKey,
    saveOpenaiAPIKey,
    groqApiKey,
    saveGroqAPIKey,
    groqModel,
    saveGroqModel,
    openaiModel,
    saveOpenaiModel,
  } = s;
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">
          API Keys <Settings className="ml-3 w-5 h-5" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>API Key</DialogTitle>
          <DialogDescription>
            Add your own OpenAI or Groq API key. It's stored locally on your
            browser only.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="select-api-provider" className="text-right">
              API Provider
            </Label>
            <SelectAPIProvider
              id="select-api-provider"
              value={provider}
              onValueChange={saveProvider}
            />
          </div>
          {provider === "openai" && (
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="openai-api-key" className="text-right">
                OpenAI API Key
              </Label>
              <Input
                id="openai-api-key"
                value={openaiApiKey}
                onChange={(e) => saveOpenaiAPIKey(e.target.value)}
                placeholder="OpenAI API key"
                className="col-span-3"
              />
            </div>
          )}

          {provider === "groq" && (
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="groq-api-key" className="text-right">
                Groq API Key
              </Label>
              <Input
                id="groq-api-key"
                value={groqApiKey}
                onChange={(e) => saveGroqAPIKey(e.target.value)}
                placeholder="Groq API key"
                className="col-span-3"
              />
            </div>
          )}
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="select-ai-model" className="text-right">
              AI Model
            </Label>
            <SelectAIModel
              id="select-ai-model"
              provider={provider}
              value={provider === "groq" ? groqModel : openaiModel}
              onValueChange={
                provider === "groq" ? saveGroqModel : saveOpenaiModel
              }
            />
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button>Save</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
