import * as React from "react";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

export const ModelsByProviders = {
  groq: [
    { id: "llama-3.1-70b-versatile", name: "LLaMA3 70b Versatile" },
    { id: "llama-3.1-8b-instant", name: "LLaMA3 8b Instant" },
    { id: "mixtral-8x7b-32768", name: "Mixtral 8x7b" },
    { id: "gemma2-9b-it", name: "Gemma2 9b" },
  ],
  openai: [
    { id: "gpt-4o", name: "GPT-4o" },
    { id: "gpt-3.5-turbo-0125", name: "GPT-3.5" },
  ],
};

export default function SelectAIModel({ provider, ...rest }) {
  const models = ModelsByProviders[provider];
  return (
    <Select {...rest}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Select AI Model" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {models.map((m) => (
            <SelectItem value={m.id}>{m.name}</SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
