import AskTubeLogo from "../logo.png";

function TopBar() {
  return (
    <div className="bg-gradient-to-r from-yellow-300 to-orange-300 p-3 flex items-center justify-center text-center">
      <p className="text-base text-gray-800">
        Quickly <b>SUMMARY</b> and <b>CHAT</b> with any <b>YouTube Video</b>!{" "}
        <a
          target="_blank"
          href="https://chromewebstore.google.com/detail/asktube-ai-youtube-video/kkdhnobdacfnbdageilfchkhcjdbkhgf"
          className="text-blue-600 font-semibold hover:underline"
          rel="noreferrer"
        >
          Try{" "}
          <img
            className="inline w-5 h-5"
            src={AskTubeLogo}
            alt="asktube icon"
          />{" "}
          AskTube.
        </a>
      </p>
    </div>
  );
}

export default TopBar;
