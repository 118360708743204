import { Bot } from "lucide-react";
import { cn } from "../lib/utils";

export default function ChatMessage({ isBot = false, content }) {
  const authorName = isBot ? "Jerry" : "Tom";
  return (
    <div className={cn("chat-message", "flex gap-2 m-3 text-gray-600 flex-1")}>
      <span className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
        <div className="rounded-full bg-gray-100 border p-1">
          {isBot ? (
            <Bot className="w-5 h-5 text-rose-700" />
          ) : (
            <Bot className="w-5 h-5 text-blue-700" />
          )}
        </div>
      </span>
      <div className={cn("leading-relaxed")}>
        <div
          className={cn("font-bold", isBot ? "text-rose-700" : "text-blue-700")}
        >
          {authorName}
        </div>
        <div>{content}</div>
      </div>
    </div>
  );
}
