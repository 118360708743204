import { cn } from "../lib/utils";

function Textarea({ id, label, helpMessage, className, ...rest }) {
  return (
    <div className={cn("mx-auto max-w-sm md:max-w-md lg:max-w-lg", className)}>
      <div>
        <label
          htmlFor={id}
          className="mb-1 block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <textarea
          id={id}
          className="block border p-3 w-full rounded-md border-gray-300 shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50"
          {...rest}
        ></textarea>
        {helpMessage && (
          <p className="mt-1 text-sm text-gray-500">{helpMessage}</p>
        )}
      </div>
    </div>
  );
}

export default Textarea;
