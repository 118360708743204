import * as React from "react";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

export default function SelectAPIProvider({ ...rest }) {
  return (
    <Select {...rest}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Select API Provider" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value="openai">OpenAI</SelectItem>
          <SelectItem value="groq">Groq</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
