import { useLocalStorage } from '@uidotdev/usehooks';


export function useAPIProviderStorage(defaultValue) {
  const storageKey = 'APIProvider'
  return useLocalStorage(storageKey, defaultValue);
}

export function useOpenaiAPIKeyStorage(defaultValue) {
  const storageKey = 'OpenaiAPIKey'
  return useLocalStorage(storageKey, defaultValue);
}

export function useGroqAPIKeyStorage(defaultValue) {
  const storageKey = 'GroqAPIKey'
  return useLocalStorage(storageKey, defaultValue);
}

export function useOpenaiModalStorage(defaultValue) {
  const storageKey = 'OpenaiModel'
  return useLocalStorage(storageKey, defaultValue);
}

export function useGroqModalStorage(defaultValue) {
  const storageKey = 'GroqModel'
  return useLocalStorage(storageKey, defaultValue);
}

